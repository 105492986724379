/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
/*@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css";*/
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "//fonts.googleapis.com/earlyaccess/notokufiarabic.css";
@import "//fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap";



.mat-tab-label .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #ffffff;
}
.mat-tab-group .mat-tab-label {
    height: 48px;
    padding: 0 4px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1;
    /*min-width: 160px;*/
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
}

/*body {*/
/*    background-image: url('assets/background/background-3.webp');*/
/*    background-size: cover; !* Ensures the image covers the entire background *!*/
/*    background-repeat: no-repeat; !* Prevents the image from repeating *!*/
/*    background-attachment: fixed; !* Keeps the background image fixed during scrolling *!*/
/*    background-position: center; !* Centers the background image *!*/
/*}*/
body {
    position: relative;
    margin: 0;
    padding: 0;


}

body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('assets/background/backgroung-1.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    opacity: 0.5; /* Adjust the opacity level */
    z-index: -1; /* Ensures the background is behind all content */
    overflow-x: hidden;
}
